import React from 'react';
import { Layout } from '../components/Layout';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { device } from '../theme';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ShareWidget } from '../components/ShareWidget';
import { SEO } from '../components/SEO';
import { CodeHighlight } from '../components/CodeHighlight';

dayjs.extend(utc);

const StyledLayoutWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(300px, 520px) minmax(200px, auto);
  grid-template-rows: auto;
  gap: 40px;
  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
`;

const StyledFeaturedImage = styled(Img)`
  max-height: 600px;
  border-radius: 5px;
`;

const StyledArticle = styled.article``;

const StyledArticleHeader = styled.header`
  h1 {
    margin: 5px 0;
    font-size: 3rem;
    font-weight: 300;
    line-height: 1em;
  }
`;

interface Props {
  pageContext: any;
}

const PortfolioPage = ({ pageContext }: Props) => {
  const { node: portfolioItem } = pageContext.portfolioItem;
  return (
    <Layout>
      <SEO
        title={portfolioItem.title}
        description={portfolioItem.excerpt}
        ogImage={portfolioItem.featuredImage.childImageSharp.fluid.src}
      />
      <StyledLayoutWrapper>
        <StyledFeaturedImage
          fluid={portfolioItem.featuredImage.childImageSharp.fluid}
        />
        <StyledArticle>
          <StyledArticleHeader>
            <span>{portfolioItem.category}</span>
            <h1>{portfolioItem.title}</h1>
            <time>{dayjs.utc(portfolioItem.date).format('MM/DD/YYYY')}</time>
          </StyledArticleHeader>
          <ShareWidget />

          <MDXProvider components={{ code: CodeHighlight }}>
            <MDXRenderer>{portfolioItem.body}</MDXRenderer>
          </MDXProvider>
        </StyledArticle>
      </StyledLayoutWrapper>
    </Layout>
  );
};

export default PortfolioPage;
