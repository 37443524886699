import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faFacebook,
  faLinkedin,
  faInstagram,
  faDiscord,
  faReddit,
  faGithub,
  faPinterest,
} from '@fortawesome/free-brands-svg-icons';

const StyledSocialContainer = styled.div`
  background-color: ${({ theme }) => theme.cardBG};
  width: fit-content;
  padding: 10px 15px;
  border-radius: 10px;
  margin-top: 20px;
  align-items: center;
  margin-bottom: 40px;
  display: flex;
  a {
    display: inline-flex;
    align-items: center;
    :nth-child(1) {
      color: ${({ theme }) => theme.twitterColor};
    }
    :nth-child(2) {
      color: ${({ theme }) => theme.facebookColor};
    }
    :nth-child(3) {
      color: ${({ theme }) => theme.redditColor};
    }
    :nth-child(4) {
      color: ${({ theme }) => theme.linkedInColor};
    }
    :nth-child(5) {
      color: ${({ theme }) => theme.pinterestColor};
    }
  }
  svg {
    font-size: 22px;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
    &:first-child {
      margin-left: 10px;
    }
  }
`;

const ShareWidget = () => {
  const pageURL = typeof window != 'undefined' ? window.location.href : '';
  return (
    <StyledSocialContainer>
      Share:
      <a
        title="Share on twitter"
        rel="nofollow noopener"
        href={`https://twitter.com/intent/tweet?url=${pageURL}`}
      >
        <FontAwesomeIcon icon={faTwitter} />
      </a>
      <a
        title="Share on facebook"
        rel="nofollow noopener"
        href={`https://www.facebook.com/sharer/sharer.php?u=${pageURL}`}
      >
        <FontAwesomeIcon icon={faFacebook} />
      </a>
      <a
        title="Share on reddit"
        rel="nofollow noopener"
        href={`http://www.reddit.com/submit?url=${pageURL}`}
      >
        <FontAwesomeIcon icon={faReddit} />
      </a>
      <a
        title="Share on linkedin"
        rel="nofollow noopener"
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${pageURL}`}
      >
        <FontAwesomeIcon icon={faLinkedin} />
      </a>
      <a
        title="Share on pinterest"
        rel="nofollow noopener"
        href={`https://pinterest.com/pin/create/button/?url=${pageURL}`}
      >
        <FontAwesomeIcon icon={faPinterest} />
      </a>
    </StyledSocialContainer>
  );
};

export default ShareWidget;
